import Cookie from "js-cookie";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components/macro";
import { MARGIN } from "../../consts";
import ButtonBase from "../atoms/Button";
import CloseButton from "../atoms/CloseButton";
import Modal from "../atoms/Modal";
import Text from "../atoms/Text";
import Title from "../atoms/Title";

// @ts-ignore
import Video1 from "../../assets/video/intro1.mp4";
// @ts-ignore
import Video2 from "../../assets/video/intro2.mp4";
// @ts-ignore
import Video3 from "../../assets/video/intro3.mp4";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalContent = styled("div")`
  margin: 40px;
  video {
    width: 100%;
  }
`;

const Button = styled(ButtonBase)< { start?: boolean } >`
  padding: 12px 10px;
  font-size: 14px;

  display: flex;
  align-items: center;

  ${p => p.start && css`
    background-color: #21e73b;
    color: black;
  `}
  ${p => p.previous && css`
    background-color: #7f7f7f;
  `}
`;

const Buttons = styled("div")`
  display: flex;
  margin: 0 -6px;

  align-items: center;

  ${Button} {
    margin: 0 6px;
    font-family: Campton;
  }
`;

const Flex = styled("div")`
  flex: 1 1 auto;
`;

const OpenButton = styled("button")<{ isVisible: boolean }>`
  position: fixed;
  right: ${MARGIN};
  bottom: ${MARGIN};
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  background-color: black;
  opacity: 0;
  transition: opacity 300ms;
  z-index: 999;
  color: white;
  font-size: 32px;
  font-family: Campton;
  font-weight: 700;
  ${(p) =>
    p.isVisible &&
    css`
      opacity: 1;
    `}
`;

const Checkbox = styled('label')`
  font-weight: 600;
  font-family: Campton;
`

const COPY = [
  {
    title: "Ontdek connecties tussen artikelen - 1/3",
    text: "Klik op een artikel en zie hoe dit is verbonden met andere artikelen op NEMO Kennislink."
  },
  {
    title: "Begrijp waarom de connecties bestaan - 2/3",
    text: "Beweeg je muis over de verbindingen en zie waarom de connectie is gelegd. Klik op de connectie en lees hier meer over in het kader linksboven. Zo kan je rondkijken en de meest interessante connecties volgen naar nieuwe artikelen."
  },
  {
    title: "Bekijk de samenvatting en lees nieuwe artikelen - 3/3",
    text: "Scan de samenvattingen en klik in het kader linksboven op 'Lees artikel' om het volledige artikel te lezen. Zo ontdek je op NEMO Kennislink altijd iets nieuws."
  }
]


const AppModal = () => {
  const [page, setPage] = useState(0);
  const isNoShow = Cookie.get("no-show-modal") === "true";
  const [isVisible, setVisible] = useState(!isNoShow);
  const hide = useCallback(() => setVisible(false), []);

  let video;
  if (page === 0) {
    video = Video1;
  } else if (page === 1) {
    video = Video2;
  } else {
    video = Video3;
  }

  return (
    <>
      <OpenButton onClick={() => setVisible(true)} isVisible={!isVisible}>
        ?
      </OpenButton>
      <Modal isVisible={isVisible}>
        <CloseButton onClick={hide} />
        <ModalContent>
          <Title as="h4">{COPY[page].title}</Title>
          <video src={video} muted autoPlay loop />
          <Text>
            {COPY[page].text}
          </Text>
          <Buttons>
            <Checkbox>
              <input
                type="checkbox"
                defaultChecked={isNoShow}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    Cookie.set("no-show-modal", "true", { expires: 31 });
                  } else {
                    Cookie.remove("no-show-modal");
                  }
                }}
              />{" "}
              Deze tips niet meer tonen
            </Checkbox>
            <Flex />
            {page > 0 && (
              <Button
                key="vorige"
                onClick={() => setPage((p) => p - 1)}
                previous
                before={
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{ marginRight: 5 }}
                  />
                }
              >
                Vorige
              </Button>
            )}
            {page > 1 ? (
              <Button key="start" onClick={hide} start after={
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ marginLeft: 5 }}
                />
              }>
                Start
              </Button>
            ) : (
              <Button
                key="volgende"
                onClick={() => setPage((p) => p + 1)}
                after={
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: 5 }}
                  />
                }
              >
                Verder
              </Button>
            )}
          </Buttons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AppModal;
