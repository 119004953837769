import React, { createContext, MutableRefObject, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components/macro";
import { BREAKPOINT_MOBILE } from "../../consts";
import { Hiarchy } from "../../store/filter/getPositions";
import { useFilterContext } from "../../store/filter/provider";
import { useSelectionContext } from "../../store/selection/provider";
import useMediaQuery from "../../utils/useMediaQuery";
import BlurGroup from "../atoms/svg/BlurGroup";
import { Circle } from "../Circle";
import { CircleLabel } from "../CircleLabel";
import { LineGroup } from "../LineGroup";
import { LineLabel } from "../LineLabel";

const LabelGroupContext = createContext<MutableRefObject<SVGGElement>>(null);
export const useLabelContext = () => useContext(LabelGroupContext);

const Svg = styled("svg")`
  width: 100%;
  height: 250px;
  overflow: visible;
  flex: 1 0 auto;

  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    width: initial;
    height: 100%;
    max-height: 100vh;
    padding-left: 320px;
  }
`;

const getLineLabelTransitionValues = (opacity: number) => ({
  x1,
  y1,
  x2,
  y2,
}: Hiarchy) => ({
  opacity,
  transform: `translate(${(x1 + x2) / 2} ${(y1 + y2) / 2})`,
});

const getCircleTransitionValues = (opacity: number) => ({
  r,
  x2,
  y2,
}: Hiarchy) => ({ opacity, r, r2: r + 4, cx: x2, cy: y2 });

const getCircleLabelTransitionValues = (opacity: number) => ({
  r,
  x2,
  y2,
}: Hiarchy) => ({
  opacity,
  transform: `translate(${x2} ${y2 + r + 6})`,
});

const Content = () => {
  const { hiarchy, lines } = useFilterContext();
  const ref = useLabelContext();
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE - 1}px)`)

  const { select } = useSelectionContext();

  const lineTransitions = useTransition(lines, {
    native: true,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    key: (item) => item.key,
  });
  const lineLabelTransitions = useTransition(hiarchy, {
    native: true,
    key: (item: Hiarchy) => item.key,
    from: getLineLabelTransitionValues(0),
    update: getLineLabelTransitionValues(1),
    enter: getLineLabelTransitionValues(1),
    leave: getLineLabelTransitionValues(0),
  });
  const circleTransitions = useTransition(hiarchy, {
    native: true,
    key: (item: Hiarchy) => item.id,
    from: getCircleTransitionValues(0),
    update: getCircleTransitionValues(1),
    enter: getCircleTransitionValues(1),
    leave: getCircleTransitionValues(0),
  });
  const circleLabelTransitions = useTransition(hiarchy, {
    native: true,
    key: (item: Hiarchy) => item.id,
    from: getCircleLabelTransitionValues(0),
    update: getCircleLabelTransitionValues(1),
    enter: getCircleLabelTransitionValues(1),
    leave: getCircleLabelTransitionValues(0),
  });

  return (
    <Svg
      viewBox={isMobile ? "0 0 400 350" : "0 0 800 700"}
      preserveAspectRatio="xMidYMid meet"
      onClick={(e) => {
        select();
      }}
    >
      {lineTransitions((props, item, { key }, index) => (
        <animated.g style={props} key={`line-${key}`}>
          <LineGroup itemKey={item.key} {...item} />
        </animated.g>
      ))}

      {circleTransitions((props, item, { key }, index) => (
        <Circle
          {...item}
          p={props}
          key={`circle-${key}`}
          // selected={item.item.slug === root || item.id === parseInt(id)}
        />
      ))}

      {lineLabelTransitions((props, item, { key }, index) => {
        return (
          <animated.g {...props} key={`line-label-g-${key}`}>
            <BlurGroup parent={item.parent.id} item={item.id} blurOpacity="0">
              <LineLabel {...item} key={`line-label-${key}`} />
            </BlurGroup>
          </animated.g>
        );
      })}

      {circleLabelTransitions((props, item, { key }, index) => {
        return (
          <BlurGroup
            item={item.id}
            blurOpacity="0.3"
            key={`circle-label-${key}`}
          >
            <CircleLabel {...item} p={props} />
          </BlurGroup>
        );
      })}
      <g ref={ref} id="label-group" />
    </Svg>
  );
};

// props: Parameters<typeof Content>[0]
const Graph = () => {
  const ref = useRef<SVGGElement>();
  return (
    <LabelGroupContext.Provider value={ref}>
      <Content />
    </LabelGroupContext.Provider>
  )
}

export default Graph