import React from "react";
import { useParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components/macro";
import useSWR from "swr";
import Spinner from "../components/atoms/Spinner";
import AppModal from "../components/organisme/AppModal";
import ConnectionPanel from "../components/organisme/ConnectionPanel";
import Filter from "../components/organisme/Filter";
import Graph from "../components/organisme/Graph";
import { BREAKPOINT_MOBILE, MARGIN } from "../consts";
import { FilterProvider } from "../store/filter/provider";
import {
  SelectionProvider
} from "../store/selection/provider";
import useTransit from "../store/transit/useTransit";
import { RootItem } from "../types";
import LogoImg from "../assets/images/home/logo.png"


/* 
  TODO?: 
  - Get hiarchy of old data
    - Render items in old data but not in new and mark them as leaving
    - Render items in data but not in old mark them as entering
    - Render items both in data & in old data & mark them as transit
    - Also mark filtered items as leaving & entering
    - Of the nieuwe data in de ouwe data zetten, zodat je 

  - Popover
  */

//  http://localhost:3000/app/nederlands-had-grote-invloed-op-frans-in-vroege-middeleeuwen

const GlobalStyles = createGlobalStyle`
  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    body, html {
      overflow: hidden;
    }
  }
`;

const Logo = styled("a")`
  display: none;
  img {
    width: 160px;
  }
  position: absolute;
  right: 20px;
  top: 20px;
  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    display: block;
  }
`


const LoaderModal = styled("div")`
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  width: 70px;
  height: 70px;
  background: rgba(256, 256, 256, 0.5);
  border-radius: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled("div")`
  background: #efefef;
  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    height: 100vh;
  }
  padding: ${MARGIN};
  flex-direction: column;
  font-family: Campton;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
`;

const Panels = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    flex: 1 1 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }

  ${ConnectionPanel}, ${Filter} {
    pointer-events: all;
  }
`;

const Flex = styled("div")`
  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
  }
`;

const Content = ({ isFirstEntry }: { isFirstEntry: boolean }) => (
  <Container>
    <GlobalStyles />

    <Graph />

    <Logo href="/">
      <img alt="Logo" src={LogoImg} />
    </Logo>
    <Panels>
      <Flex>
        <ConnectionPanel isFirstEntry={isFirstEntry} />
      </Flex>
      <Filter />
    </Panels>

  </Container>
);

const fetcher = (url: string) => fetch(url).then((r) => r.json());

function App({ isFirstEntry }: { isFirstEntry: boolean }) {
  let { root } = useParams<{ root: string; id: string }>();

  let { data, error } = useSWR<RootItem>(
    `https://old.nemokennislink.nl/api/publicaties/${root}/cirkel-tags.json?sleutel=kennislink`,
    fetcher,
    {
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnFocus: false,
      // revalidateOnMount: false
    }
  );

  const { item, isLoading } = useTransit(data);

  return (
    <SelectionProvider root={item} isFirstEntry={isFirstEntry}>
      <FilterProvider data={item} isFirstEntry={isFirstEntry}>
        {isLoading && (
          <LoaderModal>
            <Spinner />
          </LoaderModal>
        )}
        <AppModal key="modal" />
        {item && <Content isFirstEntry={isFirstEntry} />}
      </FilterProvider>
    </SelectionProvider>
  );
}

export default App;

