import React, { ReactNode } from "react";
import styled from "styled-components/macro";

const Container = styled("span")`
  font-family: Campton;
  font-size: 14px;
  font-weight: 300;
  /* line-height: 22px; */
  line-height: 1.4;
  text-decoration: underline;
  color: #000000;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Before = styled("div")`
  margin-right: 5px;
  line-height: 1;
`;

const After = styled("div")`
  margin-left: 5px;
  line-height: 1;
`;

const TextLink = (
  props: { before?: ReactNode; after?: ReactNode } & Parameters<
    typeof Container
  >[0]
) => (
  <Container {...props}>
    {props.before && <Before>{props.before}</Before>}
    <p>{props.children}</p>
    {props.after && <After>{props.after}</After>}
  </Container>
);

export default TextLink;
