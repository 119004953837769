import { Tag } from "../../types"

const getCategories = (items: Array<Tag>) => {

  let obj: {[key: number]: Tag['category']} = {}
  items.forEach(item => {
    obj[item.category.id] = item.category
  })
  return Object.values(obj)

}


export default getCategories