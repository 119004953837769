import styled from "styled-components/macro";

const Container = styled("div")`
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
`;
export default Container;
