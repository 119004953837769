import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { BREAKPOINT_MOBILE } from "../../consts";
import { RootItem, Tag } from "../../types";
import useMediaQuery from "../../utils/useMediaQuery";
import getPositions, { Hiarchy, Lines } from "./getPositions";

const FilterContext = createContext<{
  filter: Array<number>;
  root: RootItem;
  // setFilter: React.Dispatch<React.SetStateAction<number[]>>;
  toggleFilter: (id: number) => void;
  showAll: () => void;
  categories: {
    all: Array<Tag["category"]>,
    active: Set<number>,
  };
  hiarchy: Array<Hiarchy>;
  lines: Lines;
}>(null);
export const useFilterContext = () => useContext(FilterContext);

const emptyArray = [];

export const FilterProvider = ({
  data,
  isFirstEntry,
  ...props
}: { data: RootItem, isFirstEntry: boolean } & Omit<
  Parameters<typeof FilterContext.Provider>[0],
  "value"
>) => {

  const [filter, setFilter] = useState<Array<number>>(emptyArray);
  const showAll = useCallback(() => {
    setFilter(emptyArray);
  }, []);

  const toggleFilter = useCallback((id: number) => {
    setFilter((filter) => {
      if (filter.includes(id)) {
        return filter.filter((i) => i !== id);
      }
      return [...filter, id];
    });
  }, []);

  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE - 1}px)`)
  
  const { hiarchy, lines, categories } = useMemo(() => getPositions(data, filter, isFirstEntry, isMobile), [
    data,
    filter,
    isFirstEntry,
    isMobile,
  ]);

  return (
    <FilterContext.Provider
      value={{
        categories: {
          all: data?.categories,
          active: categories
        },
        hiarchy,
        lines,
        filter,
        toggleFilter,
        showAll,
        root: data
      }}
      {...props}
    />
  );
};
