import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components/macro";

const Container = styled("button")`
  width: 32px;
  height: 32px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  font-size: 18px;
`;

const CloseButton = (props: Parameters<typeof Container>[0]) => (
  <Container {...props}>
    <FontAwesomeIcon icon={faTimes} />
  </Container>
);

export default CloseButton;
