import React from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import styled from "styled-components/macro";
import useResizeObserver from "use-resize-observer";

const Elm = styled('div')<{ height: number, status: TransitionStatus, timeout: number }>`
  height: ${(p) => {
    switch (p.status) {
      case "entering":
      case "entered":
        return p.height;
      default:
        return 0;
    }
  }}px;
  transition: height ${p => p.timeout}ms;
  overflow: hidden;
`

const Collapse = ({ status, timeout = 300, ...props}: { status: TransitionStatus, timeout?: number } & React.HTMLAttributes<HTMLDivElement>) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  return (
    <Elm height={height} status={status} timeout={timeout}>
      <div ref={ref} {...props}></div>
    </Elm>
    
  )
}

export default Collapse