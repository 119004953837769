import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Item, RootItem, Tag } from "../../types";
import { getKey, Hiarchy } from "../filter/getPositions";

type Selected = {
  parent: Item;
  item: Item;
  tag?: Tag;
};

const getTags = (item?: Item, parent?: Item, root?: RootItem) => {
  if (!item || !root) return;
  const tags = root.presentTags.filter((line) => {
    return (
      parent?.tags.find(({ id }) => line.id === id) &&
      item?.tags?.find(({ id }) => line.id === id)
    );
  });
  tags?.sort((a, b) => {
    return a?.category.label.localeCompare(b?.category.label);
  });
  return tags;
};

const useSelect = ({
  root,
  id,
  tagId,
}: {
  root: RootItem;
  id: string;
  tagId: string;
}) => {
  const [selected, setSelected] = useState<Selected>();

  useEffect(() => {
    if (root) {
      setSelected({
        item: null,
        parent: null,
      });
    }
  }, [root]);

  let parent: Item;
  let item: Item;
  let tag: Tag;
  let key: string;
  if (root) {
    if (selected?.item) {
      parent = selected.parent || root;
      item = selected.item;
      tag = selected.tag;
    } else if (id) {
      item = root.children.find((i) => i.id === parseInt(id));
      parent = root;
      if (tagId) {
        tag = root.presentTags.find(({ id }) => id === parseInt(tagId));
      }
    }
  }
  if (parent && item) {
    key = getKey(parent.id, item.id);
  }
  
  // Tags between 1 & 2
  const tags = useMemo(() => getTags(item, parent, root), [item, parent, root]);

  if (!tag && tags?.length > 0) {
    tag = tags[0];
  }
  
  // Tags between 0 & 1
  const rootTags = useMemo(() => {
    return getTags(parent, root, root);
  }, [parent, root])
  
  

  return {
    parent,
    item,
    tags,
    rootTags,
    getSelectedColor: useCallback((level: number) => {
      if (parent === root) {
        return tags?.[0]?.category.color;
      }
      switch (level) {
        case 0:
        case 1:
          return rootTags?.[0]?.category.color
        case 2:
          return tags?.[0]?.category.color;
      }
    }, [rootTags, tags, parent, root]),
    isSelected: (id: number) => {
      if (!parent || !item) return false
      return id === item.id || id === parent.id || id === root.id;
    },
    isLineSelected: (item1: number, item2: number) => {
      if (!parent || !item) return false
      return (
        getKey(item1, item2) === getKey(parent.id, item.id) ||
        getKey(item1, item2) === getKey(root.id, parent.id)
      );
    },
    key,
    select: (item?: Item, parent?: Item) => {
      setSelected({
        item,
        parent,
        tag: null,
      });
    },
  };
};

export default useSelect;
