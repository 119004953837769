import React, { useEffect, useMemo, useRef, useState } from "react"
import { Link, Route, Switch, useParams } from "react-router-dom"
import styled, { css, keyframes } from "styled-components/macro"
import useSWR from "swr"
import Button from "../components/atoms/ButtonLink"
import Container from "../components/atoms/Container"
import Content from "../components/atoms/Content"
import Text from "../components/atoms/Text"
import TextLink from "../components/atoms/TextLink"
import Title from "../components/atoms/Title"
import { BG_GREY, BREAKPOINT_MOBILE, hideOnMobile, YELLOW } from "../consts"
import { TransitionStatus } from "react-transition-group/Transition"
import ErikaImg from "../assets/images/home/erica.png"
import HomeImg from "../assets/images/home/home.svg"

import Partner1Img from "../assets/images/home/partner1.png"
import PartnerConnectImg from "../assets/images/home/partner_connect.png"
import Partner2Img from "../assets/images/home/partner2.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import LogoImg from "../assets/images/home/logo.png"
import useMediaQuery from "../utils/useMediaQuery"

const Header = styled("div")`
  background-color: white;
`

const Block = styled(Container)<{ color: string }>`
  background-color: ${(p) => p.color};
`

const Buttons = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin: -7px -12px;
  margin-bottom: calc(60px - 7px);
`

const ArticleButtons = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-left: -10px;
  margin-right: -10px;
`

const Skip = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const Qoute = styled("div")`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`

const Columns = styled("div")<{ paddingTop?: string; paddingBottom?: string }>`
  display: flex;

  ${(p) =>
    p.paddingTop &&
    css`
      margin-top: ${p.paddingTop};
    `}
  ${(p) =>
    p.paddingBottom &&
    css`
      margin-bottom: ${p.paddingBottom};
    `}
`

const Column = styled("div")<{
  width?: string
  paddingTop?: string
  paddingBottom?: string
}>`
  flex: 1 ${(p) => (p.width ? `0 ${p.width}` : "1 auto")};
  ${(p) =>
    p.width &&
    css`
      width: ${p.width};
    `}

  ${(p) =>
    p.paddingTop &&
    css`
      margin-top: ${p.paddingTop};
    `}
  ${(p) =>
    p.paddingBottom &&
    css`
      margin-bottom: ${p.paddingBottom};
    `}
`

const YellowBlock = styled(Block)`
  overflow: hidden;

  ${Columns}, ${Content} {
    height: 100%;
  }
  ${Column} {
    padding-top: 42px;
    padding-bottom: 40px;
  }

  @media (min-width: 616px) {
    height: 281px;
    ${Column} {
      padding-bottom: 0;
    }
  }
`

const Erica = styled(Column)`
  position: relative;

  img {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const SvgRect = styled("rect")`
  fill: white;
`

const SvgLine = styled("line")<{
  length?: number
  delay?: number
  duration?: string
}>`
  fill: none;
  stroke: ${YELLOW};
  stroke-width: 8;

  ${(p) =>
    p.length &&
    css`
      stroke-dasharray: ${p.length};
      stroke-dashoffset: ${p.length};
      animation: ${p.duration || "600ms"}
        ${keyframes`to { stroke-dashoffset: 0 }`} forwards linear;
    `}
  ${(p) =>
    p.delay &&
    css`
      animation-delay: ${p.delay};
    `}
`

const SvgCircle = styled("circle")`
  fill: white;
  stroke: ${YELLOW};
  stroke-width: 8;
`

const ImgColumn = styled(Column)`
  ${hideOnMobile}
`

const LineContent = styled(Content)<{ height: string }>`
  position: relative;
  height: ${(p) => p.height};
  overflow: hidden;
  svg {
    width: 824px;
    height: 100%;

    position: absolute;
    left: 50%;
    margin-left: -412px;
    top: 0;
  }
`

const Line2 = styled("div")`
  position: relative;
  /* overflow: hidden; */
  svg {
    position: absolute;
    width: 32px;
    height: 281px;
    left: -80px;
    top: 0;
  }

  ${SvgLine} {
    stroke: white;
  }
`

const Space = styled("div")<{ width?: string }>`
  width: ${(p) => p.width || "35px"};
  flex: 0 0 auto;
  ${hideOnMobile}
`

const Flex = styled("div")`
  flex: 1 0 auto;
`

const TextLinks = styled("div")`
  display: flex;
`

const Partners = styled("div")`
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINT_MOBILE - 1}px) {
    flex-direction: column;
  }
  margin-top: 20px;
  margin-bottom: 35px;
`

const PartnersContent = styled(Content)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`

const SvgAnimatedLine = (props) => {
  const ref = useRef<SVGLineElement>()
  const [length, setLength] = useState<number>()
  useEffect(() => {
    if (ref.current) setLength(ref.current.getTotalLength())
  }, [ref])
  return <SvgLine length={length} {...props} ref={ref} />
}

const Categories = ({ data, link }) => {
  return (
    <Content center>
      {/*@ts-ignore*/}
      <a name="content" />
      <Title as="h2" center>
        Kies een onderwerp
      </Title>
      <Text marginBottom="35px">
        De artikelen in Taal in Verbinding zijn gekoppeld aan zes verschillende
        onderwerpen. Klik op een onderwerp en kies je startpunt.
      </Text>
      {data && (
        <Buttons>
          {data.circleCategories.map((p) => (
            <Button
              to={`/intro/${p.id}`}
              color={p.color}
              after={<FontAwesomeIcon icon={faChevronRight} />}
            >
              {p.title}
            </Button>
          ))}
        </Buttons>
      )}

      <Skip>
        <Link to={link}>
          <TextLink after={<FontAwesomeIcon icon={faChevronRight} />}>
            overslaan en toon Cirkels
          </TextLink>
        </Link>
      </Skip>
    </Content>
  )
}

const Circle = styled("div")`
  width: 43px;
  height: 43px;
  border: 3px solid #000000;
  border-radius: 50%;
  margin-bottom: 9px;
`

const Article = styled(Link)`
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  width: calc(33% - 20px);

  margin-right: 10px;
  margin-left: 10px;
`

const Articles = ({ data }) => {
  const { id } = useParams<{ id: string }>()
  if (!id || !data) return null
  const item = data.circleCategories.find((cat) => cat.id === parseInt(id))
  if (!item) return null
  return (
    <>
      <Content>
        <Title as="h2" center>
          {item.title}
        </Title>
        <Text marginBottom="35px" center>
          {item.description}
        </Text>
      </Content>
      <Content width="935px">
        <ArticleButtons>
          {item.publications.map((p) => {
            return (
              <Article to={`/entry/${p.slug}`}>
                <Circle />
                <Title as="h5">{p.title}</Title>
                <Text>{p.text}</Text>
              </Article>
            )
          })}
        </ArticleButtons>
      </Content>
      <Content>
        <Skip>
          <Link to="/">
            <TextLink before={<FontAwesomeIcon icon={faChevronLeft} />}>
              Terug naar alle onderwerpen
            </TextLink>
          </Link>
        </Skip>
      </Content>
    </>
  )
}

const URL = "https://old.nemokennislink.nl/api/cirkel-categorien.json?sleutel=kennislink"

const fetcher = (url: string) => fetch(url).then((r) => r.json())

const Intro = ({ status }: { status: TransitionStatus }) => {
  const { data, error } = useSWR(URL, fetcher)
  const lastBlock = useRef<HTMLDivElement>()
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MOBILE - 1}px)`)

  const link = useMemo(() => {
    if (!data) return null
    const a = data["highlightedPublications"]
    const i = Math.random() * (a.length - 1)
    return `/entry/${a[Math.round(i)].slug}`
  }, [data])

  return (
    <>
      <Header>
      <a href="https://www.nemokennislink.nl"><img alt="Logo" src={LogoImg} /></a>
      </Header>
      <Block color={BG_GREY}>
        <Content width="920px">
          <Columns paddingTop="73px">
            <ImgColumn>
              <img src={HomeImg} />
            </ImgColumn>
            <Space />
            <Column paddingBottom="20px">
              <Title>Taal in verbinding</Title>
              {/* <Subtitle>
                Introtekst lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Cras bibendum pharetra magna non.
              </Subtitle> */}
              <Text>
                Het unieke menselijke taalvermogen komt voort uit vele miljarden
                hersenverbindingen. Het onderzoeksproject Language in
                Interaction brengt deze bijzondere eigenschap in kaart: van
                genen tot hersenen tot gedrag.
                <br />
                <br />
                NEMO Kennislink schrijft vanuit elke mogelijke invalshoek over
                taal. Al deze artikelen zijn inhoudelijk met elkaar verbonden,
                vergelijkbaar met de neuronen in ons brein. NEMO Kennislink
                Cirkels maakt deze verbindingen zichtbaar, zodat je nog
                gerichter verder kunt lezen.
              </Text>
              <TextLinks>
                <a
                  href="#content"
                  onClick={(e) => {
                    e.preventDefault()
                    lastBlock.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }}
                >
                  <TextLink>Introductie</TextLink>
                </a>
                <Flex />
                <Link to={link}>
                  <TextLink after={<FontAwesomeIcon icon={faChevronRight} />}>
                    Direct naar Taal in Verbinding-Cirkels
                  </TextLink>
                </Link>
              </TextLinks>
            </Column>
          </Columns>
        </Content>

        {!isMobile && (
          <LineContent width="824px" height="251px">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 824 251">
              <SvgAnimatedLine
                as="path"
                strokeMiterlimit="10"
                d="M412.5,19v103c0,21.3-17.2,38.5-38.5,38.5H245.7H66.5C45.2,160.5,28,177.7,28,199l0,0v52"
              />
              <SvgCircle cx="412" cy="19" r="15" />
            </svg>
          </LineContent>
        )}
      </Block>
      <YellowBlock color={YELLOW} ref={lastBlock}>
        <Content>
          {!isMobile && (
            <Line2>
              <svg version="1.1" x="0px" y="0px" viewBox="0 0 32 281">
                <SvgRect x="0" y="70" width="32" height="16" />
                <SvgAnimatedLine x2="4" y2="281" x1="4" y1="0" delay="600ms" />
              </svg>
            </Line2>
          )}

          <Columns>
            <Column>
              <Text>
                <Qoute>
                  “De afgelopen jaren las en schreef ik veel artikelen over
                  taal. Elke keer ben ik weer onder de indruk hoe bijzonder,
                  omvangrijk en compleet ons communicatiesysteem is. Alles hangt
                  met elkaar samen. Dat zie je ook duidelijk terug in NEMO
                  Kennislink Cirkels: je blijft steeds verder klikken en nieuwe
                  dingen ontdekken.”
                </Qoute>
                <b>Erica Renckens</b>
                <br />
                Eindredacteur &amp; Redacteur thema Over Taal Gesproken
              </Text>
            </Column>
            <Erica width="80px">
              <img src={ErikaImg} />
            </Erica>
          </Columns>
        </Content>
      </YellowBlock>

      <Block color={BG_GREY}>
        {!isMobile && (
          <LineContent width="824px" height="202px">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 824 202">
              <SvgAnimatedLine
                as="path"
                delay="1.2s"
                strokeMiterlimit="10"
                d="M28,0v50.5C28,71.8,45.2,89,66.5,89h307c21.3,0,38.5,17.2,38.5,38.5V202"
              />
            </svg>
          </LineContent>
        )}

        <PartnersContent color="white">
          <Title as="h3">i.s.m. Language in Interaction</Title>
          <Partners>
            <img src={Partner1Img} />
            <Space width="8px" />
            <img src={PartnerConnectImg} />
            <Space width="8px" />
            <img src={Partner2Img} />
          </Partners>
          <Text>
            Language in Interaction is de grootste groep samenwerkende
            taalwetenschappers ter wereld. Experts uit de genetica,
            neurobiologie, psychologie, linguïstiek en taaltechnologie
            ontrafelen de universele grondslagen van het menselijk taalsysteem.
            <br />
            <br />
            Bekijk{" "}
            <a href="https://www.nemokennislink.nl/over-ons/partners/language-in-interaction/">
              <TextLink>Language in Interaction</TextLink>
            </a>{" "}
            op NEMO Kennislink
          </Text>
        </PartnersContent>

        {!isMobile && (
          <LineContent width="824px" height="172px">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 824 172">
              <SvgAnimatedLine
                delay="2s"
                duration="300ms"
                strokeMiterlimit="10"
                x1="412"
                y1="0"
                x2="412"
                y2="138"
              />
              <SvgCircle cx="412" cy="134" r="15" />
            </svg>
          </LineContent>
        )}
      </Block>

      <Block color={BG_GREY}>
        <Switch>
          <Route
            exact
            path="/"
            children={<Categories data={data} link={link} />}
          />
          <Route exact path="/intro/:id" children={<Articles data={data} />} />
        </Switch>
      </Block>
    </>
  )
}

export default Intro
