import { css } from "styled-components/macro"


export const BG_GREY = "#fafafa"

export const YELLOW = "#ffe600"
export const BLUE = "#0d9ffa"
export const ORANGE = "#fab430"
export const GREEN = "#00eab4"
export const RED = "#ff6334"
export const PURPLE = "#d86cff"


export const MARGIN = "20px"


export const BREAKPOINT_MOBILE = 819


export const hideOn = (breakpoint: number) => css`
  @media (max-width: ${breakpoint - 1}px) {
    display: none;
  }
`

export const hideOnMobile = hideOn(BREAKPOINT_MOBILE)