import React, {
  createContext,

  useContext,
  useState
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Item, RootItem } from "../../types";
import useSelect from "./useSelect";

const SelectionContext = createContext<
  ReturnType<typeof useSelect> & {
    hover: number,
    setHover: React.Dispatch<React.SetStateAction<number>>, 
    back: () => void
  }
>(null);

export const useSelectionContext = () => useContext(SelectionContext);

export const SelectionProvider = ({ root, isFirstEntry, ...rest }: { root: RootItem, isFirstEntry: boolean } & Omit<
  Parameters<typeof SelectionContext.Provider>[0],
  "value"
>) => {
  const router = useHistory()
  const { id, tagId } = useParams<{ id: string; tagId: string }>();
  const { select, ...props } = useSelect({ root, id, tagId })
  const [hover, setHover] = useState<number>()
  return (
    <SelectionContext.Provider
      value={{
        ...props,
        hover,
        setHover,
        select: (item?: Item, parent?: Item) => {
          select(
            item,
            parent,
          );
          if (!item && id) {
            router.push(`/${isFirstEntry ? "entry" : "app"}/${root.slug}`)
          }
        },
        back: () => {
          select()
          router.push(`/${isFirstEntry ? "entry" : "app"}/${root.slug}`)
        }
      }}
      {...rest}
    />
  );
};
