import { RootItem } from "../../types";
import { useState, useEffect } from "react";

export type AppTransitionStatus = "entering" | "entered" | "transit"


const useTransit = (data?: RootItem) => {

  const [oldData, setOldData] = useState<RootItem>();
  const [isTransit, setTransit] = useState<boolean>(true)
  const [status, setStatus] = useState<AppTransitionStatus>("entering")

  useEffect(() => {
    if (data) {
      setOldData(data);
      if (!isTransit) {
        setStatus("entered")
      }
    } else {
      if (oldData) {
        setStatus("transit")
      }
    }
  }, [data, isTransit, oldData]);

  useEffect(() => {
    if (status !== "entered") {
      setTransit(true)
      const t = setTimeout(() => {
        setTransit(false)
      }, 300)
      return () => {
        clearTimeout(t)
      }
    }
  }, [status])


  return {
    item: data ? data : oldData,
    isLoading: !data,
    status
  }
}

export default useTransit