import React from "react"
import styled, { css } from "styled-components/macro"

const Overlay = styled('div')<{ isVisible: boolean }>`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  opacity: 0;
  transition: all 300ms;
  pointer-events: none;

  ${p => p.isVisible && css`
    opacity: 1;
    pointer-events: all;
  `}

`

const Container = styled('div')<{ isVisible: boolean }>`
  max-width: 100%;
  max-height: 100%;
  width: 616px;
  /* height: 483px; */
  box-shadow: 0 3px 40px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  transform: translate(50vw, 50vh) scale(0, 0);
  transition: all 300ms;

  ${p => p.isVisible && css`
    transform: translate(0, 0) scale(1, 1);
  `}
`

const Modal = (props: Parameters<typeof Container>[0]) => {
  return (
    <Overlay isVisible={props.isVisible}>
      <Container {...props} /> 
    </Overlay>
  )
}

export default Modal