import { RootItem, Item, Tag } from "../../types";
import getDotAngle from "../../utils/getDotAngle";

const getRatio = (level: number) => {
  switch (level) {
    case 0:
      return 23;
    case 1:
      return 16;
    default:
      return 10;
  }
};

export const getKey = (parent: number, item: number) => {
  return parent > item ? `${parent}-${item}` : `${item}-${parent}`;
};

const filterTags = (
  parent: Item,
  item: Item,
  presentTags: Array<Tag>,
  filter: Array<number>
) => {
  const r = presentTags.filter((line) => {
    return (
      !filter.includes(line.category.id) &&
      parent.tags.find(({ id }) => line.id === id) &&
      item?.tags?.find(({ id }) => line.id === id)
    );
  });

  return r;
};

const getLinePositions = (
  parent: { id: number; x2: number; y2: number },
  item: { id: number; x2: number; y2: number }
) => {
  // Always draw the line the same way so it's doesn't flip what animating
  if (item.id < parent.id) {
    return {
      x1: item.x2,
      y1: item.y2,
      x2: parent.x2,
      y2: parent.y2,
    };
  }
  return {
    x1: parent.x2,
    y1: parent.y2,
    x2: item.x2,
    y2: item.y2,
  };
};

const getChildProps = (
  parent: Item,
  item: Item,
  cx: number,
  cy: number,
  angle: number,
  presentTags: Array<Tag>,
  level: number,
  filter: Array<number>,
  isMobile: boolean = false
) => {
  const newRadius = angle / (180 / Math.PI);

  let x = cx;
  let y = cy;
  if (level > 0) {
    // const m = 150 // newAngle === 0 || newAngle === 180 ? 150 : 300;
    const m = isMobile ? 150 : level <= 1 ? 200 : 150; //newAngle === 0 || newAngle === 180 ? 120 : 160;

    const moveX = m * Math.sin(newRadius);
    const moveY = m * Math.cos(newRadius);
    x = cx + moveX;
    y = cy + moveY;
  }

  return {
    id: item.id,
    key: getKey(parent.id, item.id),
    parent,
    item,
    r: getRatio(level),
    isHiarchy: true,
    x1: cx,
    y1: cy,
    x2: x,
    y2: y,
    // angle,
    level,
    items: filterTags(parent, item, presentTags, filter),
  };
};

export type Hiarchy = ReturnType<typeof getChildProps>;

type Props = {
  item: Item;
  parent: Item;
  x: number;
  y: number;
  level: number;
  angle: number;
};

type Line = {
  parent: Item,
  item: Item,
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  key: string;
  level: number;
  isHiarchy: boolean;
  items: Array<Tag>;
};

const getLines = (
  hiarchy: Array<Hiarchy>,
  root: RootItem,
  filter: Array<number>
) => {
  // const done = [];
  const items = new Map<string, Line>();

  hiarchy.forEach((item) => {
    if (item.level === 0) {
      return;
    }

    if (!items.has(item.key)) {
      items.set(item.key, {
        ...item,
        ...getLinePositions(
          { id: item.parent.id, x2: item.x1, y2: item.y1 },
          { id: item.id, x2: item.x2, y2: item.y2 }
        ),
      });
    }
  });

  // hiarchy.forEach((item) => {
  //   hiarchy.forEach((subitem) => {
  //     if (subitem.id === item.id) return null;

  //     const key = getKey(item.id, subitem.id);
  //     if (items.has(key)) {
  //       return;
  //     }

  //     const tags = filterTags(
  //       item.item,
  //       subitem.item,
  //       root.presentTags,
  //       filter
  //     );
  //     if (tags.length === 0) {
  //       return;
  //     }

  //     items.set(key, {
  //       item: item.item,
  //       parent: subitem.item,
  //       key,
  //       level: Math.min(item.level, subitem.level),
  //       isHiarchy: false,
  //       items: tags,
  //       ...getLinePositions(item, subitem),
  //     });
  //   });
  //   // .filter(item => !!item)
  // });

  return Array.from(items.values());
};

export type Lines = ReturnType<typeof getLines>;

export const getMaxArticles = (isFirstEntry: boolean, level = 0) => {
  return isFirstEntry ? (level < 1 ? 2 : 2) : (level < 1 ? 6 : 2)
}

const getPositions = (root: RootItem, filter: Array<number>, isFirstEntry: boolean, isMobile: boolean = false) => {
  if (!root) return {};
  const maxLevel = isMobile ? 1 : 2
  const items = new Map<number, Hiarchy>();
  const categories = new Set<number>()

  const all = root.children.flatMap((value) => {
    const {children, ...v} = value
    return [v, ...children]
  })

  const _getChildPosition = ({ parent, item, level, angle }: Props) => {

    const newProps = items.get(item.id)
    if (!newProps) return

    const children = all
      ?.filter(
        (child, i) =>
          child.id !== item.id &&
          child.id !== parent.id &&
          !items.has(child.id) &&
          filterTags(item, child, root.presentTags, filter).length > 0
      )
      .filter((_, i) => i < getMaxArticles(isFirstEntry, level));
    
    children?.forEach((child, i) => {
      const newAngle = getDotAngle(
        i,
        children.length,
        level > 0 ? angle : undefined
      );
      _getPositions({
        parent: item,
        item: child,
        x: newProps.x2,
        y: newProps.y2,
        level: level + 1,
        angle: level > 0 ? newAngle : newAngle + angle,
      });
    });
    
    children?.forEach((child, i) => {
      const newAngle = getDotAngle(
        i,
        children.length,
        level > 0 ? angle : undefined
      );
      _getChildPosition({
        parent: item,
        item: child,
        x: newProps.x2,
        y: newProps.y2,
        level: level + 1,
        angle: level > 0 ? newAngle : newAngle + angle,
      });
    });
    
  }

  const _getPositions = ({ parent, item, x, y, level, angle }: Props) => {
    if (level > maxLevel) return false
    if (items.has(item.id)) {
      return false
    }
    
    const newProps = getChildProps(
      parent,
      item,
      x,
      y,
      angle,
      root.presentTags,
      level,
      filter,
      isMobile
    );

    // Do not add when item has no tags matches with parent
    if (newProps.items.length === 0 && level > 0) {
      return false;
    }

    // Add the item
    items.set(item.id, newProps);

    newProps.items.forEach(tag => {
      categories.add(tag.category.id)
    })

    return true;
  };

  const settings = {
    parent: root,
    item: root,
    x: isMobile ? 200 : 400,
    y: isMobile ? 160 : 320,
    level: 0,
    angle: root.children.length <= 4 ? 45 : 0,
  }

  _getPositions(settings);
  _getChildPosition(settings)

  const hiarchy = Array.from(items.values());
  // return array
  return {
    hiarchy,
    lines: getLines(hiarchy, root, filter),
    categories,
  };
};

export default getPositions;
