import React from "react";
import styled, { css } from "styled-components/macro";
import Button from './Button'
import { Link } from "react-router-dom";

const Container = styled(Link)<{ wide?: boolean }>`
  display: block;

  width: 100%;
  color: white;
  font-weight: 700;
  text-decoration: none;

  ${p => !p.wide && css`
    @media (min-width: 420px) {
      width: calc(50% - 24px);
    }
  `}
  margin: 7px 12px;

`;


const ButtonLink = ({to, wide, ...props}: Parameters<typeof Button>[0]) => (
  <Container to={to} wide={wide}>
    <Button {...props} wide />
  </Container>
)


export default ButtonLink