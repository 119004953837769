import { TransitionStatus } from "react-transition-group/Transition";
import styled from "styled-components/macro";

const Fade = styled("div")<{ status: TransitionStatus, timeout?: number }>`
  transition: opacity ${p => p.timeout || 300}ms cubic-bezier(0.4, 0.0, 0.2, 1);
  opacity: ${(p) => {
    switch (p.status) {
      case "entering":
      case "entered":
        return 1;
      default:
        return 0;
    }
  }};
`;
export default Fade