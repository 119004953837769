import styled, { css } from "styled-components/macro";

const Title = styled('h1')<{ as?: string, marginBottom?: string, center?: boolean }>`
  color: #000000;
  font-family: Campton;
  font-size: ${p => {
    switch(p.as) {
      default:
      case "h1": return "48px"
      case "h2": return "32px"
      case "h3": return "24px"
      case "h4": return "18px"
      case "h5": return "14px"
    }
  }};
  font-weight: 700;
  line-height: 1.2;

  ${p => p.center && css`text-align: center;`}

  margin-top: 0;
  margin-bottom: ${p => p.marginBottom || "0.4em"};
`

export default Title