import React from "react";
import { createPortal } from "react-dom";
import { animated } from "react-spring";
import styled from "styled-components/macro";
import { useSelectionContext } from "../store/selection/provider";
import { TextBackground } from "./atoms/svg/TextBackground";
import { useLabelContext } from "./organisme/Graph";

const StyledLabel = styled(TextBackground)<{ level: number }>`
  stroke-width: 3px;
  text-anchor: start;
  pointer-events: none;
  font-size: ${(p) => {
    switch (p.level) {
      case 0:
        return "12px";
      case 1:
        return "11px";
      default:
        return "10px";
    }
  }};
  font-weight: ${(p) => {
    switch (p.level) {
      case 0:
        return "700";
      default:
        return "300";
    }
  }};
`;

export const CircleLabel = (props: Parameters<typeof StyledLabel>[0]) => {

  const ref = useLabelContext();
  const { hover } = useSelectionContext()
  const isHover = hover === props.id

  const el = (
    <StyledLabel
      verticalAnchor="start"
      backgroundProps={{
        opacity: isHover ? "1" : "0.8",
        fill: "#efefef"
      }}
      width={250}
      level={props.level}
      textAnchor="middle"
    >{props.item.title}</StyledLabel>
  );

  return (
    <>
      <animated.g {...props.p}>{el}</animated.g>
      {isHover &&
        ref.current &&
        createPortal(
          <animated.g {...props.p} pointerEvents="none">
            {el}
          </animated.g>,
          ref.current
        )}
    </>
  );
};
