import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components/macro";
import { BG_GREY } from "../consts";
import { useFilterContext } from "../store/filter/provider";
import { useSelectionContext } from "../store/selection/provider";
import { Item } from "../types";

const CircleElm = styled("circle")<{ level: number; blur: boolean }>`
  stroke: ${(p) => (p.blur ? "#bfbfbf" : "black")};
  stroke-width: 3px;
  fill: ${(p) => (p.blur ? BG_GREY : "white")};
  /* transition: all 300ms; */
  cursor: pointer;
`;

const CircleSelected = styled("circle")`
  /* stroke: #bfbfbf; */
  stroke-width: 3px;
`;

const AnimatedCircle = animated(CircleElm);
const AnimatedSelection = animated(CircleSelected);

type Props = {
  item: Item;
  parent: Item;
  x2: number;
  y2: number;
  r: number;
  level: number;
  p: Parameters<typeof AnimatedSelection>[0];
};

export const Circle = ({
  item,
  parent,
  x2,
  y2,
  r,
  p,
  level,
  ...props
}: Props) => {
  const router = useHistory();
  const { root } = useFilterContext();
  const {
    select,
    item: selectedItem,
    isSelected,
    setHover,
    getSelectedColor,
  } = useSelectionContext();

  const selected = isSelected(item.id);
  
  let stroke = useMemo(() => {
    return getSelectedColor(level)
  }, [getSelectedColor, level]);
  

  return (
    <g
      onClick={(e) => {
        e.stopPropagation();
        if (item.id === root.id) {
          return
        }
        if (selected) {
          router.push(`/app/${item.slug}`);
        } else {
          select(item, parent);
        }
      }}
      onMouseOver={() => setHover(item.id)}
      onMouseOut={() => setHover(null)}
    >
      {selected && (
        <AnimatedSelection stroke={stroke} {...props} {...p} r={p.r2} />
      )}
      <AnimatedCircle {...props} {...p} blur={!!selectedItem && !selected} />
    </g>
  );
};
