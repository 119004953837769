import React from "react";
import { animated, useSpring } from "react-spring";
import { useSelectionContext } from "../../../store/selection/provider";

const BlurGroup = ({
  item,
  parent,
  blurOpacity = "0.3",
  ...props
}: { item: number; parent?: number; blurOpacity?: string } & Parameters<
  typeof animated.g
>[0]) => {
  const { item: selectedItem, isSelected, isLineSelected } = useSelectionContext();
  const p = useSpring({
    opacity: selectedItem && !(parent ? isLineSelected(parent, item) : isSelected(item)) ? blurOpacity : "1"
  })
  return (
    <animated.g {...props} {...p} />
  );
};

export default BlurGroup;
