import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import styled, { createGlobalStyle } from "styled-components/macro";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Intro from "./pages/Intro";
import App from "./pages/App";

// @ts-ignore
import FontBold from "./assets/fonts/Rene Bieder - Campton Book Bold.otf"
// @ts-ignore
import Font from "./assets/fonts/Rene Bieder - Campton-Book.otf"

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-M58WFGD'
}

TagManager.initialize(tagManagerArgs)


const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: Campton;
    src: url('${FontBold}') format("opentype");
    font-weight: 700;
  }
  @font-face {
    font-family: Campton;
    src: url('${Font}') format("opentype");
    font-weight: 300;
  }


  #root {
    /* width: 100%; */
    /* overflow-y: hidden; */
    height: 100%;
    width: 100%;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <Router basename="/cirkels/taalinverbinding">
      <Switch>
        <Route exact path="/" children={<Intro status="entered" />} />
        <Route exact path="/intro/:id" children={<Intro status="entered" />} />
        <Route exact path="/entry/:root" children={<App key="app" isFirstEntry={true} />} />
        <Route exact path="/entry/:root/:id" children={<App key="app" isFirstEntry={true} />} />
        <Route exact path="/app/:root" children={<App key="app" isFirstEntry={false} />} />
        <Route exact path="/app/:root/:id" children={<App key="app" isFirstEntry={false} />} />
        <Route
          exact
          path="/app/:root/:id/:tagId"
          children={<App key="app" isFirstEntry={false} />}
        />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
