import React, { useMemo, useEffect } from "react";
import styled from "styled-components/macro";
import { Item, Tag } from "../types";
import getLineMovement from "../utils/getLineMovement";
import getCategories from "../store/filter/getCategories";
import {useSpring, animated} from 'react-spring'
import { useSelectionContext } from "../store/selection/provider";

// export const Line = styled(animated.line)`
//   stroke-width: 2px;
// `;

const Group = styled("g")<any>`
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(${p => p.x}px, ${p => p.y}px) rotate(${p => p.angle}deg);
`;

const Line = ({x1, y1, x2, y2, strokeWidth, color, opacity, ...rest}) => {
  const props = useSpring({
    x1,
    y1,
    x2,
    y2,
    strokeWidth,
    stroke: color,
    opacity: opacity,
  })
  return <animated.line
    {...props}
    {...rest}
  />
}

type Props = {
  items: Array<Tag>;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  parent: Item;
  item: Item;
  level: number;
  itemKey: string;
  isHiarchy: boolean;
};

export const LineGroup = ({
  items,
  parent,
  item,
  x1,
  y1,
  x2,
  y2,
  level,
  isHiarchy,
  itemKey,
  ...rest
}: Props) => {

  const {
    key: selectedKey,
    isLineSelected
  } = useSelectionContext();

  const newRadius = Math.atan2(y2 - y1, x2 - x1) // * 180 / Math.PI;
  
  const colors = useMemo(() => {
    const a = getCategories(items)
    a.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    return a
  }, [items])

  
  let opacity: number
  let strokeWidth: number
  let color: string
  if (isHiarchy) {
    if (level <= 1) {
      strokeWidth = 2
      opacity = 1
    } else {
      opacity = 1
      strokeWidth = 2
    }
  } else {
    color = "grey"
    opacity = 0.1
    strokeWidth = 1
  }
  
  const isSelected = isLineSelected(parent.id, item.id)
  if (!!selectedKey && !isSelected) {
    opacity = isHiarchy ? 0.3 : 0
  }
  if (isSelected) {
    strokeWidth = isHiarchy ? 4 : 2
  }

  return (
    <>
      {colors.map((cat, i) => {
        const m = getLineMovement(i, colors.length, strokeWidth + 1);
        const moveX = m * Math.sin(newRadius * -1);
        const moveY = m * Math.cos(newRadius * -1);
        return (
          <Line
            key={`line-${i}`}
            color={color || cat.color}
            opacity={opacity}
            strokeWidth={`${strokeWidth}px`}
            x1={x1 + moveX}
            y1={y1 + moveY}
            x2={x2 + moveX}
            y2={y2 + moveY}
            {...rest}
          />
        )
      })}
    </>
  );
};
