import getLineMovement from "./getLineMovement"
import preventRange from "./preventRange"

const getDotAngle = (i: number, length: number, currentAngle: number) => {
  if (currentAngle !== undefined) {
    const a = getLineMovement(i, length, (120 / length))
    return preventRange(currentAngle + a)
  }
  return preventRange(i * (360 / length))
}

export default getDotAngle