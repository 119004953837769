import styled, { css } from "styled-components/macro";

const Content = styled("div")< { width?: string, color?: string, center?: boolean } >`
  box-sizing: border-box;
  width: 100%;
  max-width: ${(p) => p.width || "616px"};
  ${p => p.color && css`background-color: ${p.color};`}
  ${p => p.center && css`text-align: center;`}
  @media (max-width: ${(p) => p.width || "616px"}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default Content