import { Text } from "@vx/text";
import { TextProps } from "@vx/text/lib/Text";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  backgroundProps: React.SVGAttributes<SVGRectElement>,
  // padding?: number,
} & TextProps

export const TextBackground = ({
  backgroundProps,
  // padding,
  className,
  ...props
}: Props) => {
  const ref = useRef<SVGTextElement>();

  const [pos, setPos] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>();

  useEffect(() => {
    const t1 = setTimeout(() => {
      if (ref.current) {
        setPos(ref.current.getBBox());
      }
    }, 1);
    const t2 = setTimeout(() => {
      if (ref.current) {
        setPos(ref.current.getBBox());
      }
    }, 2000);
    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, [ref, props]);

  return (
    <g className={className}>
      {pos && backgroundProps && (
        <rect
          transform={`translate(${pos?.x - 2} ${pos?.y})`}
          rx={5}
          ry={5}
          width={pos?.width + 4}
          height={pos?.height}
          {...backgroundProps}
        />
      )}
      {/* @ts-ignore */}
      <Text verticalAnchor="middle" {...props} innerRef={ref}></Text>
    </g>
  );
};
