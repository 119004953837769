import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import styled, { css } from "styled-components/macro"
import { useFilterContext } from "../../store/filter/provider"
import { useSelectionContext } from "../../store/selection/provider"
import Fade from "../atoms/transitions/Fade"
import Collapse from "../atoms/transitions/Collapse"
import { Item, RootItem, Tag } from "../../types"
import Text from "../atoms/Text"
import { BREAKPOINT_MOBILE, hideOnMobile, MARGIN } from "../../consts"
import { getMaxArticles } from "../../store/filter/getPositions"
import TextLink from "../atoms/TextLink"

const Container = styled("div")`
  /* margin-right: 20px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    flex: 1 1 100%;
    width: 320px;
    /* align-self: flex-start; */
    z-index: 99;
    max-height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: ${MARGIN} 0 0 ${MARGIN};
  }
`

const BackButtonWrapper = styled("div")`
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 20px;
`

const BackButton = styled("button")<{ hasSelection: boolean }>`
  transform: translateX(${(p) => (p.hasSelection ? "0" : "20px")});
  transition: transform 300ms;
  background-color: #000000;
  width: 100%;
  height: 100%;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled("h1")`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`

const Content = styled("div")`
  margin-bottom: 15px;
`

const Header = styled("div")<{ hasSelection: boolean }>`
  background-color: #ffe600;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;

  ${Content} {
    padding: 10px 16px;
  }
`

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  background-color: white;
`

const Rect = styled("div")<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(p) => p.color};
`

const TagElm = styled("a")<{ selected: boolean; color: string }>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 300;
  color: black;

  border: 2px solid ${(p) => p.color};
  border-radius: 25px;

  padding: 2px 7px;
  text-decoration: none;
  font-weight: 700;
  margin-right: 5px;

  ${(p) =>
    p.selected
      ? css`
          background-color: ${p.color};
          color: white;
        `
      : css`
          color: ${p.color};
        `}
`

const Tags = styled("div")`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: -10px;
`

const ItemWrapper = styled("div")`
  position: relative;
  padding-left: 20px;
  margin-left: 17px;
`

const ConnectionLink = styled(Link)`
  padding: 10px 16px;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  color: black;
  text-decoration: none;
  cursor: pointer;
`

const Connections = styled("div")`
  @media (min-width: ${BREAKPOINT_MOBILE}px) {
    overflow: auto;
    /* flex: 1 1 100%; */
  }
  background-color: #ffffff;
`

const ConnectionText = styled("div")`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

const DotPos = "calc(50% - 7px)"

const HorizontalLine = styled("div")<{ color: string }>`
  width: 7px;
  height: 2px;
  background-color: ${(p) => p.color};
  position: absolute;
  left: -10px;
  top: 11px;
`

const Dot = styled("div")<{ bottom?: boolean; top?: boolean; color: string }>`
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  left: -6px;
  top: ${DotPos};
  border: 2px solid black;
`

const Line = styled("div")<{ bottom?: boolean; top?: boolean; color: string }>`
  position: absolute;
  background-color: ${(p) => p.color};
  width: 2px;
  /* height: 100%; */
  top: ${(p) => (p.top ? DotPos : "0")};
  ${(p) =>
    p.bottom
      ? css`
          height: ${DotPos};
        `
      : css`
          bottom: 0;
        `};
  left: 0;

  ${Dot} {
    border: 2px solid ${(p) => p.color};
  }
`

const ItemArrow = styled("div")``

const ItemLink = styled(Link)`
  padding: 13px 20px 13px 0;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  color: black;
  text-decoration: none;
`

const ItemTitle = styled("div")`
  flex: 1;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
`

const Body = styled("div")`
  padding: 13px 20px 13px 0;
`

const GlobalBackButton = styled("a")`
  background-color: white;
  padding: 10px 15px;
  color: #000000;
  cursor: pointer;
  font-family: Campton;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
`

const ReadArticle = styled("a")`
  background-color: #21e83b;
  padding: 10px 15px;
  color: #000000;
  font-family: Campton;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
`

const FadeCollapse = ({ visible, timeout = 300, ...props }) => {
  return (
    <Transition in={visible} timeout={timeout} mountOnEnter unmountOnExit>
      {(status) => (
        <Collapse status={status} timeout={timeout}>
          <Fade status={status} timeout={timeout} {...props} />
        </Collapse>
      )}
    </Transition>
  )
}

const ImgWrapper = styled(FadeCollapse)`
  position: relative;
`

const Img = styled("div")<{ src: string }>`
  height: 150px;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center center;

  ${hideOnMobile}
`

const TagContent = ({
  root,
  item,
  parent,
  tags,
  renderBefore,
  beforeTag,
}: {
  root: RootItem
  item: Item
  parent: Item
  tags: Tag[]
  beforeTag?: Tag
  renderBefore?: (tag: Tag) => React.ReactNode
}) => {
  const [tag, setTag] = useState<Tag>(tags[0])
  const parentTag = useMemo(
    () => parent?.tags?.find(({ id }) => id === tag.id),
    [parent, tag]
  )
  const itemTag = useMemo(
    () => item.tags.find(({ id }) => id === tag.id),
    [item, tag]
  )

  return (
    <>
      {renderBefore?.(tag)}
      <ItemWrapper>
        <Line color={tag.category.color} />
        <Body>{parentTag?.content}</Body>
        <Tags>
          <HorizontalLine color={tag.category.color} />
          {tags.map((child) => (
            <TagElm
              onClick={() => setTag(child)}
              selected={tag.id === child.id}
              color={child.category.color}
            >
              {child.label}
            </TagElm>
          ))}
        </Tags>
        {itemTag?.content && <Body>{itemTag?.content}</Body>}
      </ItemWrapper>
      <ItemWrapper>
        {beforeTag && <Line top color={beforeTag.category.color} />}
        <Line bottom color={tag.category.color} />
        <Dot top color={tag.category.color} />
        <ItemLink to={`/app/${item.slug}`}>
          <ItemTitle
            dangerouslySetInnerHTML={{ __html: item.title }}
          ></ItemTitle>
          <ItemArrow>
            <FontAwesomeIcon icon={faArrowRight} />
          </ItemArrow>
        </ItemLink>
      </ItemWrapper>
    </>
  )
}

const TextContent = styled(Text)<{ isTruncat: boolean }>`
  ${(p) =>
    p.isTruncat &&
    css`
      /* height: 42px; */
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  margin-bottom: 0;
`

const ConnectionPanel = ({
  className,
  isFirstEntry,
}: {
  className?: string
  isFirstEntry: boolean
}) => {
  const [isTruncat, setTruncat] = useState(true)
  const { root } = useFilterContext()
  const { item, tags, parent, rootTags, back } = useSelectionContext()
  const hasThree = parent?.id !== root?.id
  const hasSelection = !!item
  const router = useHistory()

  return (
    <Container className={className}>
      <Header hasSelection={hasSelection}>
        <BackButtonWrapper>
          <BackButton onClick={back} hasSelection={hasSelection}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackButton>
        </BackButtonWrapper>
        <ImgWrapper visible={!hasSelection}>
          <Img src={`${root.card}`} />
          <GlobalBackButton onClick={() => router.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </GlobalBackButton>
          <ReadArticle href={root.url.replaceAll("old.nemokennislink.nl", "www.nemokennislink.nl")} target="_blank">
            Lees artikel
          </ReadArticle>
        </ImgWrapper>
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: root.title }}></Title>
          <FadeCollapse visible={!hasSelection}>
            <TextContent
              isTruncat={isTruncat}
              dangerouslySetInnerHTML={{ __html: root.lead }}
            />
            {isTruncat ? (
              <TextLink onClick={() => setTruncat(false)}>Uitklappen</TextLink>
            ) : (
              <TextLink onClick={() => setTruncat(true)}>Inklappen</TextLink>
            )}
          </FadeCollapse>
        </Content>
      </Header>
      {hasSelection ? (
        <Wrapper>
          <Content>
            <TagContent
              key={`bottom-${item.id}`}
              tags={tags}
              root={root}
              item={item}
              parent={hasThree ? parent : root}
              renderBefore={(tag) =>
                hasThree && (
                  <TagContent
                    key={`middle-${parent.id}`}
                    tags={rootTags}
                    root={root}
                    item={parent}
                    parent={root}
                    beforeTag={tag}
                  />
                )
              }
            />
          </Content>
        </Wrapper>
      ) : (
        <Connections>
          {root.children.map((item, i) =>
            i < getMaxArticles(isFirstEntry) ? (
              <ConnectionLink
                to={`/${isFirstEntry ? "entry" : "app"}/${root.slug}/${
                  item.id
                }`}
              >
                <ConnectionText
                  dangerouslySetInnerHTML={{ __html: item.title }}
                ></ConnectionText>
                <ItemArrow>
                  <FontAwesomeIcon icon={faArrowRight} />
                </ItemArrow>
              </ConnectionLink>
            ) : undefined
          )}
        </Connections>
      )}
    </Container>
  )
}

export default styled(ConnectionPanel)``
