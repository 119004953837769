import React from "react";
import styled, { css } from "styled-components/macro";
import { Category, Tag } from "../../types";
import { useFilterContext } from "../../store/filter/provider";
import { BG_GREY, hideOnMobile, MARGIN } from "../../consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Text from "../atoms/Text"

const Container = styled("div")`
  width: 320px;
  background-color: #ffffff;
  margin: ${MARGIN};
  margin-right: 0;

  ${hideOnMobile}
`;

const Header = styled("div")`
  border-bottom: 1px solid #efefef;
  padding: 14px 20px;
  display: flex;
  align-items: center;
`;

const Title = styled("h1")`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  flex: 1;
  margin: 0;
`;

const Reset = styled("div")`
  color: #000000;
  font-family: Campton;
  font-size: 12px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
`;

const Content = styled("div")`
  padding: 14px 20px;
  display: flex;
  flex-wrap: wrap;
`;

const Checkbox = styled("div")<{ checked?: boolean; color: string }>`
  width: 13px;
  height: 13px;
  box-sizing: border-box;
  ${(p) =>
    p.checked
      ? css`
          background-color: ${p.color};
        `
      : css`
          border: ${p.color} solid 2px;
        `}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  svg {
    color: white;
    font-size: 7px;
  }
`;

const ItemLabel = styled("label")<{ checked?: boolean }>`
  ${(p) =>
    p.checked &&
    css`
      font-weight: 700;
    `}
`;
const ItemContainer = styled("div")<{ isActive: boolean }>`
  width: 50%;
  flex: 1 1 50%;
  margin-bottom: 10px;
  display: flex;

  transition: opacity 300ms;

  ${(p) =>
    !p.isActive &&
    css`
      opacity: 0.2;
    `}

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const Item = ({ categorie }: { categorie: Category }) => {
  const { categories, toggleFilter, filter } = useFilterContext();
  const checked = !filter.includes(categorie.id);
  const id = `filter-item-${categorie.id}`;
  const isActive = !checked || categories.active.has(categorie.id);

  return (
    <ItemContainer
      isActive={isActive}
      onClick={isActive ? () => toggleFilter(categorie.id) : undefined}
    >
      <Checkbox checked={checked} color={categorie.color}>
        {checked && isActive && <FontAwesomeIcon icon={faCheck} />}
      </Checkbox>
      <ItemLabel checked={checked} htmlFor={id}>
        {categorie.label}
      </ItemLabel>
    </ItemContainer>
  );
};

const Filter = ({ className }: { className?: string }) => {
  const { categories, showAll } = useFilterContext();
  return (
    <Container className={className}>
      <Header>
        <Title>Filter</Title>
        <Reset onClick={showAll}>Reset</Reset>
      </Header>
      <Content>
        <Text>De artikelen in Taal in Verbinding zijn gekoppeld aan zes verschillende onderwerpen.</Text> 
        {categories.all?.map((categorie) => (
          <Item categorie={categorie} />
        ))}
      </Content>
    </Container>
  );
};

export default styled(Filter)``;
