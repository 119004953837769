import styled, { keyframes } from "styled-components/macro";

const Spinner = styled("div")`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: ${keyframes`
  100% {
    transform: rotate(1turn);
  }
`} 0.75s linear infinite;
`;

export default Spinner