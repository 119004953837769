import React, { ReactNode } from "react";
import styled, { css } from "styled-components/macro";

const Container = styled("button")<{ color: string; wide?: boolean }>`
  display: flex;
  background-color: ${(p) => p.color || "black"};
  border: none;

  color: white;
  font-family: Campton;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;

  ${(p) =>
    p.wide &&
    css`
      width: 100%;
    `}

  padding: 14px 20px;

  display: flex;
  align-items: center;

  p {
    margin: 0;
    /* margin-right: 5px; */
    flex: 1;
    text-align: left;
  }

  svg {
    font-size: 17px;
  }
`;

const Before = styled("div")`
  margin-right: 5px;
  line-height: 1;
`;

const After = styled("div")`
  margin-left: 5px;
  line-height: 1;
`;

const Comp = (
  props: { before?: ReactNode; after?: ReactNode } & Parameters<
    typeof Container
  >[0]
) => (
  <Container {...props}>
    {props.before && <Before>{props.before}</Before>}
    <p>{props.children}</p>
    {props.after && <After>{props.after}</After>}
  </Container>
);

const Button = styled(Comp)``

export default Button;
