import styled, { css } from "styled-components/macro";

const Text = styled('div')< { marginBottom?: string, center?: boolean } >`
  color: #000000;
  font-family: Campton;
  font-size: 14px;
  font-weight: 300;
  /* line-height: 20px; */
  line-height: 1.4;
  
  ${p => p.center && css`text-align: center;`}

  margin-bottom: ${p => p.marginBottom || "20px"};
`

export default Text