import React, { useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import { useSelectionContext } from "../store/selection/provider";
import { Item, Tag } from "../types";
import { TextBackground } from "./atoms/svg/TextBackground";
import { useLabelContext } from "./organisme/Graph";


const StyledLabel = styled(TextBackground)<{ isHover: boolean }>`
  font-size: 8px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
`;

type LabelProps = {
  x: number;
  y: number;
  color: string;
  level: number;
  item: Item;
  parent: Item;
} & Parameters<typeof StyledLabel>[0];

export const Label = ({
  x,
  y,
  color,
  level,
  item,
  parent,
  ...props
}: LabelProps) => {

  let [isHover, setHover] = useState<boolean>(false);
  
  const ref = useLabelContext();
  const { item: selectedItem, isLineSelected } = useSelectionContext();
  const isSelected = isLineSelected(parent?.id, item.id);

  // if (!!selectedItem && !isSelected) {
  //   return null;
  // } else if (isSelected) {
  //   // isHover = true;
  // }

  const el = (
    <StyledLabel
      {...props}
      width={100}
      textAnchor="middle"
      verticalAnchor="middle"
      backgroundProps={{fill: color}}
      fill="white"
      // backgroundOpacity="0.8"
      isHover={isHover}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    />
  );
  
  return (
    <>
      {level < 2 || isSelected ? (
        el
      ) : (
        <circle
          // rx={5}
          // ry={5}
          width={10}
          height={10}
          stroke={color}
          fill="white"
          r={4}
          strokeWidth="2px"
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        />
      )}
      {isHover &&
        ref.current &&
        createPortal(
          <g pointerEvents="none" transform={`translate(${x} ${y})`}>
            {el}
          </g>,
          ref.current
        )}
    </>
  );
};

type Props = {
  item: Item;
  parent: Item;
  level: number;
  items: Array<Tag>;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const LineLabel = ({
  item,
  parent,
  level,
  items,
  x1,
  y1,
  x2,
  y2,
  // itemKey
}: Props) => {
  const { select } = useSelectionContext();
  const child = items.find(() => true);
  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;
  return (
    <g
      // transform={`translate(${midX} ${midY})`}
      key="label"
      onClick={(e) => {
        e.stopPropagation();
        select(item, parent);
      }}
    >
      {child && level > 0 && (
        <Label
          item={item}
          parent={parent}
          color={child.category.color}
          x={midX}
          y={midY}
          level={level}
          width={150}
        >
          {child.label}
        </Label>
      )}
    </g>
  );
};
