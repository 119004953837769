import { useState, useEffect } from "react";

const useMediaQuery = (query) => {
  // if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return false;
  
  const mediaQuery = window.matchMedia?.(query);
  const [match, setMatch] = useState(!!mediaQuery.matches);
  
  useEffect(() => {    
    if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return;
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, []);

  return !!mediaQuery.matches;
};

export default useMediaQuery